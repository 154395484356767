import React from 'react';
import Icon from '@ant-design/icons';

import { InfoIcon } from '../../../assets/icons';

import styles from './ActiveBetFundingCell.module.scss';

export default function ActiveBetFundingCell() {
  return (
    <span className={styles.wrapper}>
      -
      <Icon component={InfoIcon} className={styles.infoIcon} />
    </span>
  );
}
