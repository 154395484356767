import { useEffect } from 'react';

import setupAxiosInterceptors from '../api/axiosInstance';

const ApiService = () => {
  useEffect(() => {
    setupAxiosInterceptors();
  }, []);

  return null;
};

export default ApiService;
