import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

const TIMEOUT = 20 * 60 * 1000;

const BASE_URL = 'https://dev.tglabs-trading.limeup.online/api';

export const requester = axios.create({
  baseURL: BASE_URL,
});

requester.defaults.timeout = TIMEOUT;

const TOKEN =
  'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImV4cCI6MTczMjk1ODYxOCwiYXV0aCI6IlJPTEVfQURNSU4gUk9MRV9VU0VSIiwiaWF0IjoxNzMwMzY2NjE4fQ.ZiNz8eqKs80k8XC45yk0mRC4I2DHKztCVkaiNHdRn-rCCeUQV5zt0-fFG57D4VQJFhIYEVuO76pBMORNs_-tbQ';

const setupAxiosInterceptors = () => {
  const onRequestSuccess = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    if (TOKEN && config.headers) {
      config.headers.Authorization = `Bearer ${TOKEN}`;
    }
    return config;
  };

  const onResponseSuccess = (response: AxiosResponse): AxiosResponse => {
    return response;
  };

  const onResponseError = (err: AxiosError) => {
    const status = err.response?.status ?? 0;

    if (status === 403) {
      console.log('status', status);
    }

    return Promise.reject(err);
  };

  requester.interceptors.request.use(onRequestSuccess);
  requester.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
