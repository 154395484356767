import React, { useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'react-use';
import cn from 'classnames';
import { addDays, getUnixTime } from 'date-fns';

import { EntityId, ResolutionString, widget as Widget } from '../../charting_library-data/charting_library';
import datafeed from '../../charting_library-data/source/datafeed';
import { DEFAULT_INTERVAL, DEFAULT_SYMBOL, TickResolution } from '../../constants/constatnts';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { ActiveBet } from '../../types/entities';

import style from './TVChart.module.scss';

let tvWid: any;

type TVChartProps = {
  asset: string | undefined;
  interval?: any;
  containerId?: string;
  betOnAsset?: ActiveBet | null;
  decimalPlaces?: number;
};

type BetLinesOnChart = {
  betId?: number;
  entryPriceId?: string;
  stopLossId?: string;
  bustPriceId?: string;
  takeProfitId?: string;
};

export default function TVChart({ asset, interval, containerId, betOnAsset, decimalPlaces = 2 }: TVChartProps) {
  const widgetRef = useRef<any>();
  const [chartReady, setChartReady] = useState(false);
  const betRef = useRef(betOnAsset);
  betRef.current = betOnAsset;
  const [betLineOnChart, setBetLineOnChart] = useState<BetLinesOnChart>({});
  const { currentAsset } = useTypedSelector((state) => state.assets);
  const prevPrice = usePrevious(currentAsset?.lastPrice);

  // direction for tick line color
  const direction = useMemo(() => {
    if (prevPrice && currentAsset?.lastPrice) {
      if (prevPrice < currentAsset?.lastPrice) return 'UP';
      if (prevPrice > currentAsset?.lastPrice) return 'DOWN';
    }
    return null;
  }, [currentAsset, prevPrice]);

  const widgetProps = useMemo(
    () => ({
      symbol: asset || DEFAULT_SYMBOL,
      interval: (interval || DEFAULT_INTERVAL) as ResolutionString,
      containerId: containerId || 'chart',
    }),
    [asset, containerId, interval],
  );

  const colorGreen = '#0E8A50';
  const colorRed = '#C0180C';

  const chartOverrides = useMemo(
    () => ({
      'paneProperties.background': '#21242E',
      'paneProperties.backgroundType': 'solid',
      'mainSeriesProperties.areaStyle.color1': 'rgba(176, 194, 255, 0.2)',
      'mainSeriesProperties.areaStyle.color2': 'rgba(20, 21, 27, 0.2)',
      'mainSeriesProperties.areaStyle.linecolor': 'rgba(191, 152, 255, 1)',
      'mainSeriesProperties.candleStyle.upColor': colorGreen,
      'mainSeriesProperties.candleStyle.borderUpColor': colorGreen,
      'mainSeriesProperties.candleStyle.wickUpColor': colorGreen,
      'mainSeriesProperties.candleStyle.wickDownColor': colorRed,
      'mainSeriesProperties.candleStyle.downColor': colorRed,
      'mainSeriesProperties.candleStyle.borderDownColor': colorRed,
      'scalesProperties.textColor': '#9096AF',
      'scalesProperties.fontSize': 12,
    }),
    [],
  );

  const disabledFeatures = useMemo(
    () => [
      'header_widget',
      'timeframes_toolbar',
      'left_toolbar',
      'legend_widget',
      'control_bar',
      'main_series_scale_menu',
      'symbol_search_hot_key',
      'show_pro_features',
      'context_menus',
      'adaptive_logo',
      'border_around_the_chart',
      'popup_hints',
      'insert_indicator_dialog_shortcut',
    ],
    [],
  );

  // @ts-ignore
  // const demo = useMemo(() => new UDFCompatibleDatafeed('https://demo-feed-data.tradingview.com'), []);

  useEffect(() => {
    setChartReady(false);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeframe = { from: getUnixTime(addDays(new Date(), -2)), to: getUnixTime(addDays(new Date(), 1)) };
    const widgetOptions = {
      symbol: widgetProps.symbol || DEFAULT_SYMBOL,
      // datafeed: demo,
      datafeed: datafeed,
      interval: widgetProps.interval,
      container: widgetProps.containerId,
      library_path: '/charting_library/',
      locale: 'en',
      disabled_features: [...disabledFeatures],
      enabled_features: ['remove_library_container_border', 'fix_left_edge', 'seconds_resolution', 'tick_resolution'],
      fullscreen: false,
      autosize: true,
      theme: 'Dark',
      custom_css_url: './TVChart.css',
      timezone: timezone,
      timeframe: timeframe,
      custom_formatters: {
        priceFormatterFactory: (symbolInfo: any, minTick: any) => {
          return {
            format: (price: number, signPositive: any) => {
              if (price === betRef.current?.stopLossPrice) {
                return 'Stop Loss';
              } else if (price === betRef.current?.takeProfitPrice) {
                return 'Take Profit';
              } else if (price === betRef.current?.openPrice) {
                return 'Entry Price';
              } else if (price === betRef.current?.bustPrice) {
                return 'Bust Price';
              }
              return price.toFixed(decimalPlaces);
            },
          };
        },
      },
    };

    // @ts-ignore
    tvWid = new Widget(widgetOptions);
    tvWid.onChartReady(() => {
      widgetRef.current = tvWid;
      tvWid.applyOverrides(chartOverrides);
      // tvWid.chart().removeAllStudies(); //TODO: possible, may be removed on own datafeed

      if (interval === TickResolution) {
        tvWid.chart().setChartType(3);
      } else {
        tvWid.chart().setChartType(1);
      }
      setChartReady(true);
    });

    return () => {
      if (widgetRef.current) {
        widgetRef.current.remove();
        widgetRef.current = null;
      }
    };
  }, [widgetProps, disabledFeatures, chartOverrides, interval, betRef, decimalPlaces]);

  useEffect(() => {
    if (chartReady && widgetRef.current && typeof widgetRef.current.chart === 'function') {
      const chart = widgetRef.current.chart?.();
      if (!chart) {
        return;
      }
      if (betOnAsset) {
        setBetLineOnChart((betLines) => {
          if (betLines.betId) {
            if (betLines.bustPriceId) chart.removeEntity(betLines.bustPriceId as EntityId);
            if (betLines.entryPriceId) chart.removeEntity(betLines.entryPriceId as EntityId);
            if (betLines.takeProfitId) chart.removeEntity(betLines.takeProfitId as EntityId);
            if (betLines.stopLossId) chart.removeEntity(betLines.stopLossId as EntityId);
          }
          return {};
        });

        const entryPriceId = chart.createShape(
          { price: betOnAsset.openPrice },
          {
            shape: 'horizontal_line',
            disableSave: true,
            lock: true,
            showInObjectsTree: false,
            disableSelection: true,
            zOrder: 'bottom',
            overrides: {
              linecolor: '#9096AF',
            },
          },
        );
        const bustPriceId = chart.createShape(
          { price: betOnAsset.bustPrice },
          {
            shape: 'horizontal_line',
            disableSave: true,
            lock: true,
            showInObjectsTree: false,
            disableSelection: true,
            zOrder: 'bottom',
            overrides: {
              linecolor: '#C0180C',
            },
          },
        );
        const newLines: BetLinesOnChart = { betId: betOnAsset.id, entryPriceId, bustPriceId };
        if (betOnAsset.takeProfitPrice) {
          const takeProfitId = chart.createShape(
            { price: betOnAsset.takeProfitPrice },
            {
              shape: 'horizontal_line',
              disableSave: true,
              lock: true,
              showInObjectsTree: false,
              disableSelection: true,
              zOrder: 'bottom',
              overrides: {
                linecolor: '#0E8A50',
              },
            },
          );
          newLines.takeProfitId = takeProfitId;
        }
        if (betOnAsset.stopLossPrice) {
          const stopLossId = chart.createShape(
            { price: betOnAsset.stopLossPrice },
            {
              shape: 'horizontal_line',
              disableSave: true,
              lock: true,
              showInObjectsTree: false,
              disableSelection: true,
              zOrder: 'bottom',
              overrides: {
                linecolor: '#C0180C',
              },
            },
          );
          newLines.stopLossId = stopLossId;
        }
        setBetLineOnChart(newLines);
      }
    }
  }, [widgetRef, chartReady, betOnAsset, setBetLineOnChart]);

  //TODO: for test only, remove
  // useEffect(() => {
  //   if (chartReady && widgetRef.current && typeof widgetRef.current.chart === 'function') {
  //     widgetRef.current.applyOverrides({ 'mainSeriesProperties.priceLineColor': grow ? '#0E8A50' : '#C0180C' });
  //   }
  // }, [widgetRef, chartReady, grow]);

  return (
    <div className={cn(style.tvChartWrapper)}>
      {chartReady ? null : (
        <div className={style.loader}>
          <div className={style.loader}></div>
        </div>
      )}
      <div id={'chart'} className={cn(style.tvChart)} style={{ opacity: chartReady ? 1 : 0 }} />
    </div>
  );
}
