import eventSourceUtil from '../eventSource/eventSource';

const channelToSubscription = new Map();

function getNextBarTime(barTime, resolution) {
  const oneMinute = 1000 * 60;

  switch (resolution) {
    case '1T': {
      return new Date(barTime + 1000).getTime();
    }
    case '5S': {
      return new Date(barTime + 5000).getTime();
    }
    case '15S': {
      return new Date(barTime + 15000).getTime();
    }
    case '30S': {
      return new Date(barTime + 30000).getTime();
    }
    case '1': {
      return new Date(barTime + oneMinute).getTime();
    }
    case '5': {
      return new Date(barTime + oneMinute * 5).getTime();
    }
    case '15': {
      return new Date(barTime + oneMinute * 15).getTime();
    }
    case '30': {
      return new Date(barTime + oneMinute * 30).getTime();
    }
    case '60': {
      const date = new Date(barTime);
      date.setHours(date.getHours() + 1);
      return new Date(barTime + oneMinute * 60).getTime();
    }
    case '240': {
      return new Date(barTime + oneMinute * 240).getTime();
    }
    case '1D': {
      const date = new Date(barTime);
      date.setDate(date.getDate() + 1);
      return date.getTime();
    }
    default: {
      const date = new Date(barTime);
      date.setDate(date.getDate() + 1);
      return date.getTime();
    }
  }
}

export function subscribeOnStream(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscribeUID,
  onResetCacheNeededCallback,
  lastBar,
) {
  try {
    const eventSourcePrev = eventSourceUtil.getEventSourceSymbol();
    const messageFunction = eventSourcePrev && eventSourcePrev.onmessage ? eventSourcePrev.onmessage : null;

    const eventSource = eventSourceUtil.createEventSourceSymbol(symbolInfo.full_name, symbolInfo.type);
    eventSource.onmessage = function (message) {
      if (messageFunction) messageFunction(message);
      const data = JSON.parse(message.data);
      const tradePrice = data.lp;
      const tradeTime = data.t;

      const subscriptionItem = channelToSubscription.get(subscribeUID);
      if (subscriptionItem === undefined) return;
      // console.log('subscriptionItem', subscriptionItem);
      const lastItemBar = subscriptionItem.lastBar;
      if (!subscriptionItem.lastBar) return;
      const nextBarTime = getNextBarTime(lastItemBar.time, resolution);
      let bar;
      if (tradeTime >= nextBarTime) {
        bar = {
          time: nextBarTime,
          open: tradePrice,
          high: tradePrice,
          low: tradePrice,
          close: tradePrice,
        };
      } else {
        bar = {
          ...lastItemBar,
          high: Math.max(lastItemBar.high, tradePrice),
          low: Math.min(lastItemBar.low, tradePrice),
          close: tradePrice,
        };
      }

      subscriptionItem.lastBar = bar;

      // send data to every subscriber of that symbol
      subscriptionItem.handlers.forEach((handler) => handler.callback(bar));
    };
  } catch (e) {}

  const handler = {
    id: subscribeUID,
    callback: onRealtimeCallback,
  };

  let subscriptionItem = channelToSubscription.get(subscribeUID);
  if (subscriptionItem) {
    // already subscribed to the channel, use the existing subscription
    subscriptionItem.handlers.push(handler);
    return;
  }
  subscriptionItem = {
    subscribeUID,
    resolution,
    lastBar,
    handlers: [handler],
  };

  channelToSubscription.set(subscribeUID, subscriptionItem);
}

export function unsubscribeFromStream() {}
