export const BET_STEP = 1;
export const MAX_BUST_PRICE = 5000000;
export const MIN_WAGER = 0.01;

export const STORAGE_KEY = {
  RISK_WARNING_OPEN: 'isRiskWarningClosed',
  HOW_IT_WORK_OPEN: 'howItWorkOpen',
  LAST_OPENED_ASSET: 'la',
  SHOW_MY_BETS_ON_CHART: 'mb',
  SHOW_PUBLIC_BETS_ON_CHART: 'pb',
  SHOW_ADVANCED_CHART: 'ac',
};

export const DEFAULT_SYMBOL = 'BTCUSD';
export const DEFAULT_INTERVAL = '1T';

export const TickResolution = '1T';

export const RESOLUTIONS = {
  Tick: TickResolution,
  '5s': '5S',
  '15s': '15S',
  '30s': '30S',
  '1 min': '1',
  '5 min': '5',
};

export const RES = {
  '1T': 'ONE_SEC',
  '5S': 'FIVE_SEC',
  '15S': 'FIFTEEN_SEC',
  '30S': 'THIRTY_SEC',
  1: 'ONE_MIN',
  5: 'FIVE_MIN',
};

// Todo: temporary
export const PLAYER_ID = 4;

export const READY_COINS = ['BTCUSD'];
