import React from 'react';
import cn from 'classnames';

import styles from '../Step.module.scss';

export default function Step4() {
  return (
    <div className={styles.step}>
      <div className={styles.step_left}>
        <div className={styles.step_left_label}>Step 4</div>
        <h2 className={styles.step_left_title}>Place bet and Cash out</h2>
        <div className={styles.step_left_text}>
          After selecting the price direction, wager, and multiplier, <b>place your bet.</b> You can then watch the live
          price chart to monitor how your bet is performing.
        </div>
        <div className={styles.step_left_text}>
          You can <b>cash out</b> early if you're in profit or wait for the price to reach your predicted point or
          “bust” if it moves against you.
        </div>
      </div>

      <div className={cn(styles.step_right, styles.step_right_stepFourthImage)} />
    </div>
  );
}
