import { useCallback, useEffect } from 'react';
import Icon from '@ant-design/icons';
import { notification } from 'antd';

import { InfoIcon } from '../assets/icons';
import { ClosedBet } from '../types/entities';

import styles from './messages.module.scss';

export function useShowMessage() {
  const [api, contextHolder] = notification.useNotification();
  useEffect(() => {
    notification.config({});
  }, []);

  const showSuccess = useCallback(
    (message: string) => {
      api.open({
        message: 'Success!',
        description: message,
        placement: 'topLeft',
        duration: 5,
        className: styles.containerSuccess,
        icon: <Icon component={InfoIcon} className={styles.icon} />,
      });
    },
    [api],
  );

  const showError = useCallback(
    (message: string) => {
      api.open({
        message: 'Error!',
        description: message,
        placement: 'topLeft',
        duration: 5,
        className: styles.containerError,
        icon: <Icon component={InfoIcon} className={styles.icon} />,
      });
    },
    [api],
  );

  const showCashOutMessage = useCallback(
    (bet: ClosedBet, image: any) => {
      const messageHeader = `Bet ${bet?.playerCurrency} closed at profit of $${bet?.profit || 0}`;
      const message = `Wager ${bet?.amount} Multiplier: ${bet?.multiplier}, Exit price: $${bet?.closePrice || 0}, Fee: $${bet?.fee || 0}`;
      api.open({
        message: messageHeader,
        description: message,
        placement: 'bottomLeft',
        duration: 5,
        className: styles.cashOutMessage,
        icon: <img src={image} alt='' className={styles.icon} />,
      });
    },
    [api],
  );

  return { showSuccess, showError, contextHolder, showCashOutMessage };
}
