import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { requester } from '../../../api/axiosInstance';
import { GetActiveBetsResponse, GetPublicBetsResponse, RequestEditActiveBetData } from '../types';

export const getActiveBets = createAsyncThunk<GetActiveBetsResponse, number>(
  '/betList/getActiveBets',
  async (playerId: number): Promise<GetActiveBetsResponse> => {
    const response: AxiosResponse<GetActiveBetsResponse> = await requester.get(`/players/${playerId}/bets`, {
      params: { status: 'ACTIVE' },
    });
    return response.data;
  },
);

export const getClosedBets = createAsyncThunk<GetActiveBetsResponse, number>(
  '/betList/getClosedBets',
  async (playerId: number): Promise<GetActiveBetsResponse> => {
    const response: AxiosResponse<GetActiveBetsResponse> = await requester.get(`/players/${playerId}/bets`, {
      params: { status: 'CLOSED' },
    });
    return response.data;
  },
);

export const getPublicBets = createAsyncThunk<GetPublicBetsResponse, void>(
  '/betList/getPublicBets',
  async (): Promise<GetPublicBetsResponse> => {
    const response: AxiosResponse<GetPublicBetsResponse> = await requester.get('/bets');
    return response.data;
  },
);

export const editActiveBet = createAsyncThunk(
  '/betList/editActiveBet',
  async (requestData: RequestEditActiveBetData) => {
    return requester.put(`/bets/${requestData.betId}`, requestData.body);
  },
);
