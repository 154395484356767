import React, { useCallback } from 'react';
import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';

import { EqualizerActiveIcon, EqualizerIcon, EyeIcon } from '../../../assets/icons';
import useWindowSize from '../../../hooks/useWindowSize';
import { useAppDispatch } from '../../../store';
import { setSelectedBet } from '../../../store/betList/slices';
import { setBetInfoModalVisible, setBetSettingsModalVisible } from '../../../store/ui/slices';
import { ActiveBet } from '../../../types/entities';

import styles from './ActiveBetActions.module.scss';

type ActiveBetActionsProps = {
  bet: ActiveBet;
};

export default function ActiveBetActions({ bet }: ActiveBetActionsProps) {
  const dispatch = useAppDispatch();
  const { isMobile } = useWindowSize();

  const onBetSettings = useCallback(() => {
    dispatch(setSelectedBet(bet));
    dispatch(setBetSettingsModalVisible(true));
  }, [dispatch, bet]);

  const onBetInfo = useCallback(() => {
    dispatch(setSelectedBet(bet));
    dispatch(setBetInfoModalVisible(true));
  }, [dispatch, bet]);

  const isAutoBet =
    (bet?.profit ?? 0) > 0 ||
    (bet?.closePrice ?? 0) > 0 ||
    (bet?.stopLossPrice ?? 0) > 0 ||
    (bet?.takeProfitPrice ?? 0) > 0;

  return (
    <div className={styles.buttonsContainer} id={`${bet?.id}bet`}>
      {isMobile ? (
        <button className={styles.iconButton} onClick={onBetSettings}>
          <Icon component={isAutoBet ? EqualizerActiveIcon : EqualizerIcon} className={styles.icon} />
        </button>
      ) : (
        <Tooltip title={'Bet Settings'}>
          <button className={styles.iconButton} onClick={onBetSettings}>
            <Icon component={isAutoBet ? EqualizerActiveIcon : EqualizerIcon} className={styles.icon} />
          </button>{' '}
        </Tooltip>
      )}
      {isMobile ? (
        <button className={styles.iconButton} onClick={onBetInfo}>
          <Icon component={EyeIcon} className={styles.icon} />
        </button>
      ) : (
        <Tooltip title={'Bet Details'}>
          <button className={styles.iconButton} onClick={onBetInfo}>
            <Icon component={EyeIcon} className={styles.icon} />
          </button>
        </Tooltip>
      )}
    </div>
  );
}
