import React from 'react';
import cn from 'classnames';

import styles from '../Step.module.scss';

export default function Step2() {
  return (
    <div className={styles.step}>
      <div className={styles.step_left}>
        <div className={styles.step_left_label}>Step 2</div>
        <h2 className={styles.step_left_title}>Bet on the price going up or down</h2>
        <div className={styles.step_left_text}>
          Start by selecting whether you think the price of the cryptocurrency (like Bitcoin or Ethereum) will{' '}
          <b>go up</b> or <b>go down.</b> This is your prediction.
        </div>
        <div className={styles.step_left_subtext}>
          If you believe the price will rise, select <span className={styles.step_left_subtext_green}>Up</span>. If you
          think it will drop, select <span className={styles.step_left_subtext_red}>Down</span>.
        </div>
      </div>

      <div className={cn(styles.step_right, styles.step_right_stepSecondImage)} />
    </div>
  );
}
