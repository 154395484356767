import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import HowItWorksModal from './components/HowItWorksModal/HowItWorksModal';
import RiskWarningModal from './components/RiskWarningModal/RiskWarningModal';
import RoiCalculatorModal from './components/RoiCalculatorModal/RoiCalculatorModal';
import { STORAGE_KEY } from './constants/constatnts';
import ActiveBets from './pages/ActiveBets/ActiveBets';
import ClosedBets from './pages/ClosedBets/ClosedBets';
import CryptoList from './pages/CryptoList/CryptoList';
import LeaderBoard from './pages/LeaderBoard/LeaderBoard';
import MainPage from './pages/Main/MainPage';
import PublicBets from './pages/PublicBets/PublicBets';
import ApiService from './services/ApiService';
import { getAssets } from './store/assets/asyncThunks/slice';
import { setShowHowItWorks, setShowRiskAfterClose, setShowRiskWarning } from './store/ui/slices';
import { theme } from './styles/theme';
import { useAppDispatch } from './store';

import styles from './App.module.scss';

function App() {
  const dispatch = useAppDispatch();
  const isHowItWorksShown = localStorage.getItem(STORAGE_KEY.HOW_IT_WORK_OPEN) === 'true';
  const isRiskWarningShown = localStorage.getItem(STORAGE_KEY.RISK_WARNING_OPEN) === 'true';

  useEffect(() => {
    if (!isHowItWorksShown) {
      dispatch(setShowHowItWorks(true));
      if (!isRiskWarningShown) {
        dispatch(setShowRiskAfterClose(true));
      }
    } else if (!isRiskWarningShown) {
      dispatch(setShowRiskWarning(true));
    }
  }, [dispatch, isHowItWorksShown, isRiskWarningShown]);

  useEffect(() => {
    dispatch(getAssets());
  }, [dispatch]);

  useEffect(() => {
    const preventPullToRefresh = (event: any) => {
      if (event.touches.length !== 1) return; // Игнорируем мультитач
      let startY = event.touches[0].clientY;

      const handleTouchMove = (e: any) => {
        let touchY = e.touches[0].clientY;
        // Если сдвиг вниз, предотвратить действие по умолчанию
        if (touchY > startY) e.preventDefault();
      };

      document.addEventListener('touchmove', handleTouchMove, { passive: false });

      const handleTouchEnd = () => {
        document.removeEventListener('touchmove', handleTouchMove);
      };

      document.addEventListener('touchend', handleTouchEnd, { once: true });
    };

    document.addEventListener('touchstart', preventPullToRefresh);

    // Убираем обработчики при размонтировании компонента
    return () => {
      document.removeEventListener('touchstart', preventPullToRefresh);
    };
  }, []);

  return (
    <ConfigProvider theme={theme}>
      <RiskWarningModal />
      <HowItWorksModal />
      <RoiCalculatorModal />
      <ApiService />
      <div className={styles.appContainer}>
        <BrowserRouter>
          <Routes>
            <Route path={'/'} element={<CryptoList />} />
            <Route path={'/:crypto'} element={<MainPage />}>
              <Route path={'/:crypto/active-bets'} element={<ActiveBets />} />
              <Route path={'/:crypto/closed-bets'} element={<ClosedBets />} />
              <Route path={'/:crypto/public-bets'} element={<PublicBets />} />
              <Route path={'/:crypto/leaderboard'} element={<LeaderBoard />} />
            </Route>
            {/*<Route path={"/"} element={<MainPage/>} />*/}
          </Routes>
        </BrowserRouter>
      </div>
    </ConfigProvider>
  );
}

export default App;
