import { createSlice } from '@reduxjs/toolkit';

import { UserSliceState } from './types';

const initialState: UserSliceState = {
  token: null,
  player: {
    id: 777,
    userName: 'Dan Redford',
    photoUrl: 'https://picsum.photos/50',
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setPlayer: (state, action) => {
      state.player = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setToken, setPlayer } = userSlice.actions;

export default userSlice.reducer;
