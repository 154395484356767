import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';

import { READY_COINS } from '../../../constants/constatnts';
import { Asset } from '../../../types/entities';
import FormattedAmount from '../../TableCells/FormattedAmount/FormattedAmount';

import styles from './CoinSelector.module.scss';

type CoinItemProps = {
  coin: Asset;
  onSelect: () => void;
};

export default function CoinItem({ coin, onSelect }: CoinItemProps) {
  const grow = useMemo(() => Number(coin?.change) > 0, [coin?.change]);
  const down = useMemo(() => Number(coin?.change) < 0, [coin?.change]);

  const isEnabled = useMemo(() => {
    return READY_COINS.includes(coin.symbol);
  }, [coin]);

  const handleClick = useCallback(() => {
    if (isEnabled) {
      onSelect();
    }
  }, [onSelect, isEnabled]);

  return (
    <div onClick={handleClick} className={styles.row}>
      <span className={styles.pair}>
        <img src={coin.icon} alt='' className={styles.icon} />
        <span className={styles.coinName}>{coin.name}</span>
        {/*<span>/USD</span>*/}
      </span>
      {isEnabled ? (
        <>
          <span className={cn(styles.price, styles.price_value)}>
            <FormattedAmount amount={Number(coin.lastPrice)} showCurrency={false} />{' '}
          </span>
          {Number.isNaN(Number(coin?.change)) ? (
            <span className={styles.change} />
          ) : (
            <span
              className={cn(styles.change, grow && styles.change_green, down && styles.change_red)}
            >{`${grow ? '+' : down ? '-' : ''}${Math.abs(Number(coin?.change))}%`}</span>
          )}
        </>
      ) : (
        <span className={styles.comingSoon}>Coming soon</span>
      )}
    </div>
  );
}
