import React, { useCallback, useState } from 'react';
import { Checkbox, Modal } from 'antd';
import cn from 'classnames';

import { InformationIcon } from '../../assets/icons';
import coin from '../../assets/images/AnimationCoin.gif';
import { ReactComponent as RiskImage } from '../../assets/images/risk.svg';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import useWindowSize from '../../hooks/useWindowSize';
import { useAppDispatch } from '../../store';
import { setShowRiskAfterClose, setShowRiskWarning } from '../../store/ui/slices';
import CommonButton from '../CommonButton/CommonButton';

import styles from './RiskWarningModal.module.scss';

const modalStyles = {
  content: {
    padding: '0 0 24px 0',
    background: '#21242E',
    border: '#35394A solid 1px',
  },
  mask: {
    backdropFilter: 'blur(10px)',
  },
};

interface RiskWarningModalProps {
  onClose?: () => void;
}

export default function RiskWarningModal({ onClose }: RiskWarningModalProps) {
  const { isMobile, width } = useWindowSize();
  const notOpenRiskWarning = localStorage.getItem('isRiskWarningClosed') === 'true';
  const [checked, setChecked] = useState(notOpenRiskWarning);
  const dispatch = useAppDispatch();
  const { showRiskWarning } = useTypedSelector((state) => state.ui);

  const setNotOpenModal = (e: any) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      localStorage.setItem('isRiskWarningClosed', 'true');
    } else {
      localStorage.removeItem('isRiskWarningClosed');
    }
  };

  const handleClose = useCallback(() => {
    dispatch(setShowRiskWarning(false));
    dispatch(setShowRiskAfterClose(false));
    if (onClose) {
      onClose();
    }
  }, [dispatch, onClose]);

  return (
    <Modal
      open={showRiskWarning}
      onCancel={handleClose}
      maskClosable={false}
      width={isMobile ? width - 32 : 662}
      centered
      forceRender={false}
      styles={modalStyles}
      footer={[
        <div className={styles.footer}>
          <Checkbox
            onChange={setNotOpenModal}
            checked={checked}
            className={checked ? cn(styles.checkbox, styles.checked) : styles.checkbox}
          >
            Don’t show again
          </Checkbox>
          <CommonButton
            type='primaryRed'
            onClick={handleClose}
            label='I acknowledge'
            // width={isMobile ? undefined : 200}
            containerClassName={styles.footer_button}
          />
        </div>,
      ]}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <span className={styles.header_title}>Know the Risks to Succeed!</span>
        </div>

        <div className={styles.main}>
          <div className={styles.mainContent}>
            <div className={styles.main_image}>
              <img className={styles.coin} alt='' src={coin} />
              {/*<Player autoplay loop src={animationCoin} className={styles.coin} speed={1.5}></Player>*/}
              <RiskImage />
            </div>
            <div>
              <div className={styles.subheader}>The bigger the risk - the bigger possible win.</div>
              <p className={styles.text}>
                Crypto trading carries risks, and higher betting multipliers can amplify those risks.
              </p>
              <p className={styles.text}>However, at CoinPoker, you will never lose more than your initial wager.</p>
            </div>
          </div>

          <div className={styles.block}>
            <div>
              <InformationIcon />
            </div>
            <div className={styles.text}>
              If your bet remains open for more than 8 hours, funding payments will be deducted from your balance in the
              same token used for the trade. Ensure your balance can cover these payments; otherwise, your bet may
              automatically close if there's insufficient funds.
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
