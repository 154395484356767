import { createSlice } from '@reduxjs/toolkit';

import { getActiveBets, getClosedBets, getPublicBets } from './asyncThunks/slice';
import { BetListState } from './types';

const initialState: BetListState = {
  selectedBet: null,
  betOnChart: null,
  loading: false,
  activeBets: [],
  closedBets: [],
  publicBets: [],
  error: '',
};

const betListSlice = createSlice({
  name: 'betList',
  initialState,
  reducers: {
    setSelectedBet: (state, action) => {
      state.selectedBet = action.payload;
    },
    setBetOnChart: (state, action) => {
      state.betOnChart = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActiveBets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getActiveBets.fulfilled, (state, action) => {
      state.loading = false;
      state.activeBets = action.payload;
    });
    builder.addCase(getActiveBets.rejected, (state) => {
      state.loading = false;
      state.activeBets = [];
      state.error = 'Error';
    });

    builder.addCase(getClosedBets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getClosedBets.fulfilled, (state, action) => {
      state.loading = false;
      state.closedBets = action.payload;
    });
    builder.addCase(getClosedBets.rejected, (state) => {
      state.loading = false;
      state.closedBets = [];
      state.error = 'Error';
    });

    builder.addCase(getPublicBets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPublicBets.fulfilled, (state, action) => {
      state.loading = false;
      state.publicBets = action.payload.content;
    });
    builder.addCase(getPublicBets.rejected, (state) => {
      state.loading = false;
      state.publicBets = [];
      state.error = 'Error';
    });
  },
});

export const { setSelectedBet, setBetOnChart } = betListSlice.actions;
export const betListReducer = betListSlice.reducer;
