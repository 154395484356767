import React, { useEffect } from 'react';

import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useAppDispatch } from '../../store';
import { setBetOnChart } from '../../store/betList/slices';
import TVChart from '../TVChart/TVChart';

import AssetHistoricalData from './AssetHistoricalData/AssetHistoricalData';
import CoinSelector from './CoinSelector/CoinSelector';
import CurrentPrice from './CurrentPrice/CurrentPrice';
import ResolutionSelector from './ResolutionSelector/ResolutionSelector';
import SettingsDropdown from './SettingsDropdown/SettingsDropdown';

import styles from './ChartWidget.module.scss';

export default function ChartWidget() {
  const { currentAsset, resolution } = useTypedSelector((state) => state.assets);
  const { activeBets, betOnChart } = useTypedSelector((state) => state.betList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!currentAsset || !activeBets || activeBets.length === 0) {
      dispatch(setBetOnChart(null));
    } else {
      const betsOnAsset = activeBets.filter((bet) => bet.assetId === currentAsset.id);
      //TODO: sort bets if needed
      if (betsOnAsset.length > 0) {
        dispatch(setBetOnChart(betsOnAsset[0]));
      } else {
        dispatch(setBetOnChart(null));
      }
    }
  }, [activeBets, currentAsset, dispatch]);

  return (
    <div className={styles.container}>
      {!!currentAsset && !!resolution && (
        <>
          <div className={styles.header}>
            <div className={styles.left}>
              <CoinSelector />
              <CurrentPrice />
            </div>
            <div className={styles.right}>
              <ResolutionSelector />
              <SettingsDropdown />
            </div>
          </div>
          <AssetHistoricalData />
        </>
      )}
      <TVChart asset={currentAsset?.symbol} interval={resolution} betOnAsset={betOnChart} />
    </div>
  );
}
