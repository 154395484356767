import { EventSourcePolyfill } from 'event-source-polyfill';
import axios from 'axios';

const { REACT_APP_FINANCE_SERVICE, REACT_APP_NOTIFICATION_SERVICE } = process.env;

const eventSourceStatuses = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSED: 2,
};

let eventSource;
let eventSourceMain;
let eventSourceItem;
let eventSourceSymbol;
let eventSourceNotifications;
let eventSourceBalance;
let eventSourceTrades;
let eventSourceFavorites;

const getSymbol = (source = eventSourceSymbol) => {
  const index = source.url.lastIndexOf('/');
  return source.url.slice(index + 1);
};

const getSymbolFromString = (str) => {
  const symbol = str.match(/.*([A-Z]+)_#_.*$/);
  if (symbol) return symbol[1];
  return null;
};

const handleError = (eventSrc) => (event) => {
  const {
    target: { readyState, url, onmessage, onopen },
  } = event;

  if (readyState === 2) {
    eventSrc = new EventSourcePolyfill(url, {
      headers: { Authorization: axios.defaults.headers.common.Authorization },
    });
    eventSrc.onerror = handleError;
  }
  if (onmessage) eventSrc.onmessage = onmessage;
  if (onopen) eventSrc.onopen = onopen;
};

const createEventSource = () => {
  if (eventSource && eventSource.readyState !== eventSourceStatuses.CLOSED) return eventSource;
  if (eventSource && eventSource.readyState === eventSourceStatuses.OPEN) eventSource.close();
  eventSource = new EventSource(`${REACT_APP_FINANCE_SERVICE}/api/symbols/updates`);
  eventSource.onerror = handleError(eventSource);
  return eventSource;
};

// const createEventSourceMain = () => {
//   if (eventSourceMain && eventSourceMain.readyState !== eventSourceStatuses.CLOSED) return eventSourceMain;
//   if (eventSourceMain && eventSourceMain.readyState === eventSourceStatuses.OPEN) eventSourceMain.close();
//   eventSourceMain = new EventSource(`${REACT_APP_FINANCE_SERVICE}/api/symbols/updates`);
//   eventSourceMain.onerror = handleError(eventSourceMain);
//   return eventSourceMain;
// };

const createEventSourceItem = (category, symbol) => {
  eventSourceItem = new EventSource(`${REACT_APP_FINANCE_SERVICE}/api/symbols/${category}/${symbol}/updates`);
  eventSourceItem.onerror = handleError(eventSourceItem);
  return eventSourceItem;
};

// const createEventSourceTrades = () => {
//   if (!eventSourceTrades || eventSourceTrades.readyState === eventSourceStatuses.CLOSED) {
//     eventSourceTrades = new EventSource(`${REACT_APP_FINANCE_SERVICE}/api/symbols/updates`);
//     eventSourceTrades.onerror = handleError(eventSourceTrades);
//     return eventSourceTrades;
//   }
//
//   return null;
// };

// const createEventSourceFavorites = () => {
//   if (!eventSourceFavorites || eventSourceFavorites.readyState === eventSourceStatuses.CLOSED) {
//     eventSourceFavorites = new EventSource(`${REACT_APP_FINANCE_SERVICE}/api/symbols/updates`);
//     eventSourceFavorites.onerror = handleError(eventSourceFavorites);
//     return eventSourceFavorites;
//   }
//
//   return null;
// };

const createEventSourceSymbol = (symbol, category) => {
  if (eventSourceSymbol && eventSourceSymbol.readyState !== 2 && symbol === getSymbol(eventSourceSymbol))
    return eventSourceSymbol;
  if (eventSourceSymbol && (eventSourceSymbol.readyState === 1 || eventSourceSymbol.readyState === 0))
    eventSourceSymbol.close();
  eventSourceSymbol = new EventSource(`${REACT_APP_FINANCE_SERVICE}/api/symbols/${category}/${symbol}/updates`);
  eventSourceSymbol.onerror = handleError(eventSourceSymbol);
  return eventSourceSymbol;
};

// const createEventSourceNotifications = () => {
//   if (eventSourceNotifications && eventSourceNotifications.readyState !== eventSourceStatuses.CLOSED)
//     return eventSourceNotifications;
//   if (eventSourceNotifications && eventSourceNotifications.readyState === eventSourceStatuses.OPEN)
//     eventSourceNotifications.close();
//   eventSourceNotifications = new EventSourcePolyfill(`${REACT_APP_NOTIFICATION_SERVICE}/api/notifications/updates/`, {
//     headers: { Authorization: axios.defaults.headers.common.Authorization },
//     heartbeatTimeout: 36000 * 1000,
//   });
//   eventSourceNotifications.onerror = handleError(eventSourceNotifications);
//   return eventSourceNotifications;
// };

const createEventSourceBalance = () => {
  if (eventSourceBalance && eventSourceBalance.readyState !== eventSourceStatuses.CLOSED) return eventSourceBalance;
  if (eventSourceBalance && eventSourceBalance.readyState === eventSourceStatuses.OPEN) eventSourceBalance.close();
  eventSourceBalance = new EventSourcePolyfill(`/api/accounts/balance`);
  eventSourceBalance.onerror = handleError(eventSourceBalance);
  return eventSourceBalance;
};

const getEventSource = () => eventSource;
const getEventSourceSymbol = () => eventSourceSymbol;
const getEventSourceNotifications = () => eventSourceNotifications;
const closeStream = () => {
  if (eventSource) eventSource.close();
};
const closeStreamMain = () => {
  if (eventSourceMain) eventSourceMain.close();
};
const closeStreamItem = () => {
  if (eventSourceItem) eventSourceItem.close();
};
const closeStreamTrades = () => {
  if (eventSourceTrades) eventSourceTrades.close();
};
const closeStreamFavorites = () => {
  if (eventSourceFavorites) eventSourceFavorites.close();
};
const closeSymbolStream = () => {
  if (eventSourceSymbol) eventSourceSymbol.close();
};
const closeNotificationsStream = () => {
  if (eventSourceNotifications) eventSourceNotifications.close();
};
const closeBalanceStream = () => {
  if (eventSourceBalance) eventSourceBalance.close();
};

const closeAllSteam = () => {
  closeStream();
  closeStreamMain();
  closeStreamItem();
  closeStreamTrades();
  closeStreamFavorites();
  closeSymbolStream();
  closeNotificationsStream();
  closeBalanceStream();
};

export default {
  createEventSource,
  // createEventSourceMain,
  createEventSourceSymbol,
  // createEventSourceTrades,
  // createEventSourceFavorites,
  createEventSourceItem,
  getEventSource,
  getEventSourceSymbol,
  closeStream,
  closeStreamMain,
  closeStreamTrades,
  closeStreamFavorites,
  closeStreamItem,
  closeSymbolStream,
  getSymbol,
  getSymbolFromString,
  // createEventSourceNotifications,
  getEventSourceNotifications,
  closeNotificationsStream,
  createEventSourceBalance,
  closeBalanceStream,
  closeAllSteam,
};
