import React, { useCallback, useEffect } from 'react';
import { ConfigProvider, Table } from 'antd';

import ClosedBetActions from '../../components/ClosedBetActionButtons/ClosedBetActions';
import EmptyState from '../../components/EmapyState/EmptyState';
import BetPairCell from '../../components/TableCells/BetPairCell/BetPairCell';
import BetStatusCell from '../../components/TableCells/BetStatusCell/BetStatusCell';
import FormattedAmount from '../../components/TableCells/FormattedAmount/FormattedAmount';
import PNLCell from '../../components/TableCells/PNLCell/PNLCell';
import ROICell from '../../components/TableCells/ROICell/ROICell';
import { PLAYER_ID } from '../../constants/constatnts';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import useWindowSize from '../../hooks/useWindowSize';
import { useAppDispatch } from '../../store';
import { getClosedBets } from '../../store/betList/asyncThunks/slice';

import styles from '../../styles/table.module.scss';

export default function ClosedBets() {
  const dispatch = useAppDispatch();
  const { isMobile } = useWindowSize();

  const { closedBets, loading } = useTypedSelector((state) => state.betList);

  useEffect(() => {
    dispatch(getClosedBets(PLAYER_ID));
  }, [dispatch]);

  const columns = [
    {
      title: 'Bet',
      dataIndex: 'playerCurrency',
      width: 150,
      render: (_: any, record: any) => (
        <BetPairCell coin={record.assetName} direction={record.prediction} image={record.assetIcon} />
      ),
    },
    {
      title: 'Wager',
      dataIndex: 'amount',
      key: 'amount',
      width: 60,
      render: (amount: number) => (
        <span className={styles.highlighted}>
          <FormattedAmount amount={amount} />
        </span>
      ),
    },
    {
      title: 'Multiplier',
      dataIndex: 'multiplier',
      key: 'multiplier',
      width: 60,
      render: (text: string) => <span className={styles.highlighted}>{`x${text}`}</span>,
    },
    {
      title: 'Entry price',
      dataIndex: 'openPrice',
      key: 'openPrice',
      width: 110,
      render: (price: number) => <FormattedAmount amount={price} />,
    },
    {
      title: 'Bust price',
      dataIndex: 'bustPrice',
      key: 'bustPrice',
      width: 110,
      render: (price: number) => <FormattedAmount amount={price} />,
    },
    {
      title: 'Exit price',
      dataIndex: 'closePrice',
      key: 'closePrice',
      width: 110,
      render: (price: number) => <FormattedAmount amount={price} />,
    },
    {
      title: 'PNL',
      dataIndex: 'profit',
      key: 'profit',
      width: 80,
      render: (value: number) => <PNLCell value={value} />,
    },
    {
      title: 'ROI',
      dataIndex: 'roi',
      key: 'roi',
      width: 80,
      render: (value: number) => <ROICell value={value} />,
    },
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      width: 110,
      render: (value: any) => <BetStatusCell status={value} />,
    },
    {
      title: '',
      render: (_: any, bet: any) => <ClosedBetActions bet={bet} />,
      fixed: isMobile ? ('right' as any) : undefined,
      width: 80,
    },
  ];

  const renderEmptySate = useCallback(() => <EmptyState description={['No closed bets yet']} smallImg />, []);

  return (
    <ConfigProvider renderEmpty={renderEmptySate}>
      <div className={styles.tableWrapper}>
        <Table
          rowClassName={styles.row}
          dataSource={closedBets}
          columns={columns}
          pagination={false}
          rowKey='id'
          className={styles.table}
          scroll={{ x: isMobile ? 500 : 955 }}
          loading={loading}
        />
      </div>
    </ConfigProvider>
  );
}

// const dataSource: ClosedBet[] = [
//   {
//     id: 123,
//     playerId: 777,
//     playerCurrency: 'BTC',
//     prediction: Prediction.UP,
//     amount: 32.3,
//     multiplier: 100,
//     openPrice: 62345.64,
//     bustPrice: 59223.44,
//     closePrice: 62145.64,
//     profit: 2.45,
//     roi: 2.33,
//     status: BetStatus.CLOSED,
//   },
//   {
//     id: 232,
//     playerId: 777,
//     playerCurrency: 'CDA',
//     prediction: Prediction.UP,
//     amount: 34,
//     multiplier: 10,
//     openPrice: 335.64,
//     closePrice: 245.64,
//     bustPrice: 320.44,
//     profit: -2.03,
//     roi: -1.32,
//     status: BetStatus.CLOSED,
//   },
// ];
