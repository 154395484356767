import React, { useMemo } from 'react';

import styles from './PNL.module.scss';

type PNLCellType = {
  value: number | undefined;
};

export default function PNL({ value }: PNLCellType) {
  const positive = useMemo(() => Number(value) > 0, [value]);
  const negative = useMemo(() => Number(value) < 0, [value]);
  const valueString = useMemo(() => Math.abs(Number(value)), [value]);

  if (!value && value !== 0) return null;
  return (
    <span className={positive ? styles.positive : negative ? styles.negative : styles.neutral}>
      {positive && '+'}
      {negative && '-'}${valueString}
    </span>
  );
}
