import React, { useMemo } from 'react';
import type { TableProps } from 'antd';
import { Table } from 'antd';

import { formatNumber } from '../../../helpers/formatNumber';
import useWindowSize from '../../../hooks/useWindowSize';

import { upData } from './data';
import { DataType } from './types';
import { calculateROI, getColor } from './utils';

import styles from './CalculatorTable.module.scss';

interface CalculatorTableProps {
  wager: string;
  priceWillGo: string;
  multiplier: string;
  entryPrice: string;
  selected: string;
  customPriceMove?: string;
}

export default function CalculatorTable({
  wager,
  priceWillGo,
  multiplier,
  entryPrice,
  selected,
  customPriceMove = '0',
}: CalculatorTableProps) {
  const { isTablet } = useWindowSize();
  const entryPriceNumber = parseFloat(entryPrice.replace(/,/g, '')) || 0;
  const multiplierNumber = parseFloat(multiplier.replace(/,/g, '')) || 0;
  const wagerNumber = parseFloat(wager.replace(/,/g, '')) || 0;

  const renderCell = (text: string, record: DataType) => (
    <span style={{ color: getColor(record, multiplierNumber, wagerNumber) }}>{text}</span>
  );

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Price move',
      dataIndex: 'priceMove',
      key: 'priceMove',
      render: renderCell,
      width: isTablet ? '21%' : '24%',
    },
    { title: 'ROI', dataIndex: 'ROI', key: 'ROI', render: renderCell },
    { title: 'Exit price', dataIndex: 'exitPrice', key: 'exitPrice', render: renderCell },
    { title: 'PNL', dataIndex: 'PNL', key: 'PNL', render: renderCell },
  ];

  const newData = useMemo(() => {
    return upData.map((item) => {
      const priceMoveNumber = parseFloat(item.priceMove.replace(/,/g, '')) || 0;
      const { ROI, exitPrice, PNL } = calculateROI(
        priceMoveNumber,
        entryPriceNumber,
        multiplierNumber,
        wagerNumber,
        priceWillGo,
        selected,
      );
      return {
        priceMove: `${item.priceMove}%`,
        ROI: wagerNumber && multiplierNumber ? `${formatNumber(ROI)}%` : '-',
        exitPrice: formatNumber(exitPrice),
        PNL: wagerNumber && multiplierNumber ? formatNumber(PNL, true) : '-',
        key: item.key,
      };
    });
  }, [entryPriceNumber, multiplierNumber, wagerNumber, priceWillGo, selected]);

  const priceMoveNumber = parseFloat(customPriceMove.replace(/,/g, '')) || 0;
  const { ROI, exitPrice, PNL } = calculateROI(
    priceMoveNumber,
    entryPriceNumber,
    multiplierNumber,
    wagerNumber,
    priceWillGo,
    selected,
  );

  const priceMoveData = {
    priceMove: `${priceMoveNumber}%`,
    ROI: wagerNumber && multiplierNumber ? `${formatNumber(ROI)}%` : '-',
    exitPrice: formatNumber(exitPrice),
    PNL: wagerNumber && multiplierNumber ? formatNumber(PNL) : '-',
    key: priceMoveNumber,
  };

  const data: DataType[] = priceMoveNumber !== 0 ? [priceMoveData] : newData;
  return (
    <div className={styles.tableWrapper}>
      <Table
        rowClassName={styles.row}
        dataSource={data}
        columns={columns}
        pagination={false}
        rowKey='key'
        className={styles.table}
      />
    </div>
  );
}
