import { requester } from '../../api/axiosInstance';
import { format, addDays } from 'date-fns';

export async function makeApiRequest(path) {
  try {
    const { data } = await requester.get(`${path}`);
    return await data;
  } catch (err) {
    throw new Error(`request error: ${err.message}`);
  }
}

export function formatDateString(date) {
  // console.log({ date });
  return format(addDays(new Date(date * 1000), 1), 'yyyy-MM-dd');
}
