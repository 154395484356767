import React from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

import lottieData from '../../../assets/animations/step1.lottie';
import useWindowSize from '../../../hooks/useWindowSize';

import styles from '../Step.module.scss';

export default function Step1() {
  const { isMobile } = useWindowSize();
  return (
    <div className={styles.step}>
      <div className={styles.step_left}>
        <div className={styles.step_left_label}>Step 1</div>
        <h2 className={styles.step_left_title}>Choose currency</h2>
        <div className={styles.step_left_text}>
          Start by selecting which cryptocurrency you want to bet on. Common options include{' '}
          <b>Bitcoin (BTC), Ethereum (ETH),</b> or other popular cryptos available on the platform.
        </div>
        <div className={styles.step_left_subtext}>
          Once you pick your currency, you’ll see the live price chart for that asset.
        </div>
      </div>
      <div className={styles.lottie}>
        <DotLottieReact
          src={lottieData}
          loop
          autoplay
          style={{ width: isMobile ? '100%' : '400px', height: isMobile ? '100%' : '230px' }}
        />
      </div>
    </div>
  );
}
