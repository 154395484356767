import React, { useCallback, useEffect } from 'react';
import { ConfigProvider, Table } from 'antd';

import EmptyState from '../../components/EmapyState/EmptyState';
import BetInfoCell from '../../components/TableCells/BetInfoCell/BetInfoCell';
import BetPairCell from '../../components/TableCells/BetPairCell/BetPairCell';
import FormattedAmount from '../../components/TableCells/FormattedAmount/FormattedAmount';
import PlayerCell from '../../components/TableCells/PlayerCell/PlayerCell';
import PNLCell from '../../components/TableCells/PNLCell/PNLCell';
import ROICell from '../../components/TableCells/ROICell/ROICell';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import useWindowSize from '../../hooks/useWindowSize';
import { useAppDispatch } from '../../store';
import { getPublicBets } from '../../store/betList/asyncThunks/slice';

import styles from '../../styles/table.module.scss';

export default function PublicBets() {
  const dispatch = useAppDispatch();
  const { isMobile, width } = useWindowSize();

  const { publicBets, loading } = useTypedSelector((state) => state.betList);

  useEffect(() => {
    dispatch(getPublicBets());
  }, [dispatch]);

  const renderEmptySate = useCallback(() => <EmptyState description={['No public bets']} smallImg />, []);

  const columns = [
    {
      title: 'Player',
      key: 'player',
      width: 160,
      render: (_: any, record: any) => (
        <PlayerCell playerName={record.playerNickname} playerImageUrl={record.playerImageUrl} />
      ),
    },
    {
      title: 'Bet',
      key: 'playerCurrency',
      width: 150,
      render: (_: any, record: any) => (
        <BetPairCell coin={record.assetName} direction={record.prediction} image={record.assetIcon} />
      ),
    },
    {
      title: 'Wager',
      dataIndex: 'amount',
      key: 'amount',
      width: 80,
      render: (amount: number) => (
        <span className={styles.highlighted}>
          <FormattedAmount amount={amount} />
        </span>
      ),
    },
    {
      title: 'Multiplier',
      dataIndex: 'multiplier',
      key: 'multiplier',
      render: (text: string) => <span className={styles.highlighted}>{`x${text}`}</span>,
      width: 80,
    },
    {
      title: 'Entry price',
      dataIndex: 'openPrice',
      key: 'openPrice',
      render: (price: number) => <FormattedAmount amount={price} />,
      width: 110,
    },
    {
      title: 'Bust price',
      dataIndex: 'bustPrice',
      key: 'bustPrice',
      render: (price: number) => <FormattedAmount amount={price} />,
      width: 110,
    },
    {
      title: 'Exit price',
      dataIndex: 'closePrice',
      key: 'closePrice',
      render: (price: number) => <FormattedAmount amount={price} />,
      width: 110,
    },
    {
      title: 'PNL',
      dataIndex: 'profit',
      key: 'profit',
      render: (value: number) => <PNLCell value={value} />,
      width: 80,
    },
    {
      title: 'ROI',
      dataIndex: 'roi',
      key: 'roi',
      render: (value: number) => <ROICell value={value} />,
      width: 80,
    },
    {
      title: '',
      render: (_: any, bet: any) => <BetInfoCell bet={bet} />,
      fixed: width < 1030 ? ('right' as any) : undefined,
      width: 36,
    },
  ];
  return (
    <ConfigProvider renderEmpty={renderEmptySate}>
      <div>
        <Table
          dataSource={publicBets}
          columns={columns}
          rowClassName={styles.row}
          className={styles.table}
          pagination={false}
          rowKey='id'
          scroll={{ x: isMobile ? 500 : 955 }}
          loading={loading}
        />
      </div>
    </ConfigProvider>
  );
}
