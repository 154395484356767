import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { requester } from '../../../api/axiosInstance';
import { IBetRequest, IBetResponse } from '../types';

export const openBet = createAsyncThunk<IBetResponse, IBetRequest>(
  '/bet/openBet',
  async (body: IBetRequest): Promise<IBetResponse> => {
    const response: AxiosResponse<IBetResponse> = await requester.post('/bets/open', body);
    return response.data;
  },
);
