import React, { useCallback } from 'react';
import { Splitter } from 'antd';

import { QuestionMarkIcon } from '../../assets/icons';
import BetWidgetDrawer from '../../components/BetWidgetDriver/BetWidgetDrawer';
import ChartWidget from '../../components/ChartWidget/ChartWidget';
import TableTabs from '../../components/TabHeader/TableTabs';
import useWindowSize from '../../hooks/useWindowSize';
import { useAppDispatch } from '../../store';
import { setBetWidgetDrawerVisible, setMenuDrawerVisible } from '../../store/ui/slices';

import BetWidget from './BetWidget/BetWidget';

import styles from './BetPage.module.scss';

export default function MainPage() {
  const { isMobile } = useWindowSize();
  const dispatch = useAppDispatch();

  const showMenu = useCallback(() => {
    dispatch(setBetWidgetDrawerVisible(false));
    dispatch(setMenuDrawerVisible(true));
  }, [dispatch]);

  if (isMobile) {
    return (
      <>
        <Splitter layout={'vertical'} className={styles.splitter}>
          <Splitter.Panel min={200}>
            <div className={styles.chartContainerMobile}>
              <ChartWidget />
              <div className={styles.menuButton} onClick={showMenu}>
                <QuestionMarkIcon />
              </div>
            </div>
          </Splitter.Panel>
          <Splitter.Panel min={10}>
            <div className={styles.tableContainer}>
              <TableTabs />
            </div>
          </Splitter.Panel>
        </Splitter>
        <BetWidgetDrawer />
      </>
    );
  }

  return (
    <div className={styles.pageContainer}>
      <div className={styles.betContainer}>
        <div className={styles.chartContainer}>
          <ChartWidget />
        </div>
        <BetWidget />
      </div>

      <div className={styles.tableContainer}>
        <TableTabs />
      </div>
    </div>
  );
}
