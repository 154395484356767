import React, { useCallback, useMemo, useState } from 'react';
import Icon from '@ant-design/icons';
import { Checkbox, ConfigProvider, Dropdown, Menu, MenuProps } from 'antd';
import cn from 'classnames';

import { SettingsIcon } from '../../../assets/icons';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import useWindowSize from '../../../hooks/useWindowSize';
import { useAppDispatch } from '../../../store';
import { setShowAdvancedChart, setShowMyBetsOnChart, setShowPublicBetsOnChart } from '../../../store/ui/slices';
import BottomDrawer from '../../BottomDriver/BottomDrawer';
import CommonButton from '../../CommonButton/CommonButton';

import styles from './SettingsDropdown.module.scss';

export default function SettingsDropdown() {
  const dispatch = useAppDispatch();
  const [chartSettingsMenuOpen, setChartSettingsMenuOpen] = useState(false);
  const { showMyBetsOnChart, showPublicBetsOnChart, showAdvancedChart } = useTypedSelector((state) => state.ui);
  const { isMobile } = useWindowSize();

  const handleSetShowMyBets = useCallback(
    (e: any) => {
      dispatch(setShowMyBetsOnChart(e.target.checked));
    },
    [dispatch],
  );
  const handleSetShowPublicBets = useCallback(
    (e: any) => {
      dispatch(setShowPublicBetsOnChart(e.target.checked));
    },
    [dispatch],
  );
  const handleSetShowAdvancedChat = useCallback(
    (e: any) => {
      dispatch(setShowAdvancedChart(e.target.checked));
    },
    [dispatch],
  );

  const items: MenuProps['items'] = useMemo(
    () => [
      {
        key: '1',
        label: (
          <Checkbox
            onChange={handleSetShowMyBets}
            checked={showMyBetsOnChart}
            className={showMyBetsOnChart ? cn(styles.checkbox, styles.checked) : styles.checkbox}
          >
            Show my bets on the chart
          </Checkbox>
        ),
      },
      {
        key: '2',
        label: (
          <Checkbox
            onChange={handleSetShowPublicBets}
            checked={showPublicBetsOnChart}
            className={showPublicBetsOnChart ? cn(styles.checkbox, styles.checked) : styles.checkbox}
          >
            Show public bets on the chart
          </Checkbox>
        ),
      },
      {
        key: '3',
        label: (
          <Checkbox
            onChange={handleSetShowAdvancedChat}
            checked={showAdvancedChart}
            className={showAdvancedChart ? cn(styles.checkbox, styles.checked) : styles.checkbox}
          >
            Advanced chart
          </Checkbox>
        ),
      },
    ],
    [
      showMyBetsOnChart,
      showPublicBetsOnChart,
      showAdvancedChart,
      handleSetShowMyBets,
      handleSetShowAdvancedChat,
      handleSetShowPublicBets,
    ],
  );

  const onButtonClick = useCallback(
    (e: any) => {
      if (isMobile) {
        setChartSettingsMenuOpen(true);
      }
      e.target.blur();
    },
    [isMobile],
  );

  const closeDriver = useCallback(() => {
    setChartSettingsMenuOpen(false);
  }, []);

  return isMobile ? (
    <>
      <CommonButton
        containerClassName={styles.button}
        outerClassName={styles.buttonOuter}
        className={styles.buttonInner}
        height={32}
        width={32}
        type='secondary'
        onClick={onButtonClick}
        icon={<Icon component={SettingsIcon} className={styles.icon} />}
      />
      <BottomDrawer visible={chartSettingsMenuOpen} closeDriver={closeDriver} height={160}>
        <ConfigProvider
          theme={{
            components: {
              Menu: {
                controlItemBgHover: '#35394A',
                controlItemBgActive: '#35394A',
                controlItemBgActiveHover: '#35394A',
                itemBg: '#35394A',
                itemActiveBg: '#35394A',
                itemHoverBg: '#35394A',
              },
            },
          }}
        >
          <Menu items={items} mode='inline' className={styles.menu} selectable={false} onClick={closeDriver} />
        </ConfigProvider>
      </BottomDrawer>
    </>
  ) : (
    <Dropdown overlayClassName={styles.popup} menu={{ items }} trigger={'click' as any} placement='bottomRight'>
      <CommonButton
        containerClassName={styles.button}
        outerClassName={styles.buttonOuter}
        className={styles.buttonInner}
        height={38}
        width={38}
        type='secondary'
        onClick={onButtonClick}
        icon={<Icon component={SettingsIcon} className={styles.icon} />}
      />
    </Dropdown>
  );
}
