import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { ConfigProvider, Dropdown, Menu, MenuProps } from 'antd';
import cn from 'classnames';
import { addDays, format } from 'date-fns';

import { ArrowUpSecondaryIcon } from '../../../assets/icons';
import eventSourceUtil from '../../../charting_library-data/eventSource/eventSource';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import useWindowSize from '../../../hooks/useWindowSize';
import { useAppDispatch } from '../../../store';
import { getAssetVolume } from '../../../store/assets/asyncThunks/slice';
import { setCurrentAsset, updateAssetData } from '../../../store/assets/slices';
import { Asset } from '../../../types/entities';
import BottomDrawer from '../../BottomDriver/BottomDrawer';

import CoinItem from './CoinItem';

import styles from './CoinSelector.module.scss';

export default function CoinSelector() {
  const { currentAsset, assets } = useTypedSelector((state) => state.assets);
  const dispatch = useAppDispatch();
  const [coinSelectorMenuOpen, setCoinSelectorMenuOpen] = useState(false);
  const [listOpened, setListOpened] = useState(false);
  const { isMobile, height: windowHeight } = useWindowSize();
  const [listHeight, setListHeight] = useState(0);

  const onSelectCoin = useCallback(
    (coin: Asset) => {
      dispatch(setCurrentAsset(coin));
    },
    [dispatch],
  );

  // useEffect(() => {
  //   if (currentAsset?.symbol) {
  //     dispatch(
  //       getAssetVolume({
  //         symbolCode: currentAsset.symbol,
  //         from: format(addDays(new Date(), -1), 'yyyy-MM-dd'),
  //         to: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
  //         resolution: 'DAY',
  //       }),
  //     );
  //   }
  // }, [currentAsset?.symbol, dispatch]);

  const onOpen = useCallback((opened: boolean) => {
    setListOpened(opened);
  }, []);

  const openDrawer = useCallback(() => {
    setCoinSelectorMenuOpen(true);
  }, []);
  const closeDrawer = useCallback(() => {
    setCoinSelectorMenuOpen(false);
  }, []);

  const items: MenuProps['items'] = useMemo(
    () =>
      assets?.map((coin) => ({
        key: coin.id,
        label: <CoinItem onSelect={() => onSelectCoin(coin)} coin={coin} />,
      })),
    [assets, onSelectCoin],
  );

  useEffect(() => {
    if (currentAsset) {
      eventSourceUtil.closeStreamItem();
      const eventSource = eventSourceUtil.createEventSourceItem('crypto', currentAsset.symbol);
      eventSource.onmessage = (message: any) => {
        // console.log('Asset Update:', message.data);
        const data = JSON.parse(message.data);
        if (data) {
          dispatch(updateAssetData({ symbol: currentAsset.symbol, data }));
        }
      };
    }
  }, [currentAsset, dispatch]);

  useEffect(() => {
    if (assets?.length > 0) {
      const computed = assets.length * 40 + 90;
      setListHeight(computed > windowHeight - 50 ? windowHeight - 50 : computed);
    }
  }, [assets, windowHeight]);

  if (isMobile)
    return (
      <>
        <div className={styles.coinWrapper} onClick={openDrawer}>
          <img src={currentAsset?.icon} alt='' className={styles.coinImage} />
          <DownOutlined className={cn(styles.arrow, listOpened && styles.arrow_up)} />
        </div>
        <BottomDrawer visible={coinSelectorMenuOpen} closeDriver={closeDrawer} height={listHeight}>
          <div className={styles.drawerInnerWrapper}>
            <span className={styles.header}>Market</span>
            <div className={styles.listContainer} style={{ height: `${listHeight - 65}px` }}>
              <div className={styles.tableHeader}>
                <span className={styles.pair}>Pair</span>
                <span className={styles.price}>Price</span>
                <span className={styles.change}>24h Change</span>
              </div>

              <Menu
                onClick={closeDrawer}
                defaultSelectedKeys={[String(currentAsset?.id)]}
                mode='inline'
                items={items}
              />
            </div>
          </div>
        </BottomDrawer>
      </>
    );

  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Dropdown: {
              paddingBlock: 8,
              controlItemBgHover: '#4B5063',
              controlItemBgActive: '#35394A',
              controlItemBgActiveHover: '#4B5063',
            },
          },
        }}
      >
        <Dropdown
          onOpenChange={onOpen}
          trigger={'click' as any}
          overlayClassName={styles.dropdownContainer}
          menu={{ items }}
          dropdownRender={(menu) => (
            <div>
              <div className={styles.tableHeader}>
                <span className={styles.pair}>Pair</span>
                <span className={styles.price}>Price</span>
                <span className={styles.change}>24h Change</span>
              </div>
              {React.cloneElement(menu as React.ReactElement, { style: { boxShadow: 'none' } })}
            </div>
          )}
        >
          <div className={styles.coinWrapper}>
            <img src={currentAsset?.icon} alt='' className={styles.coinImage} />
            {!isMobile && <span className={styles.coinHeader}>{currentAsset?.name}</span>}
            <ArrowUpSecondaryIcon
              style={{ transform: listOpened ? 'scaleY(1)' : 'scaleY(-1)', transition: '0.3s all', color: '#fff' }}
            />
          </div>
        </Dropdown>
      </ConfigProvider>
    </div>
  );
}
